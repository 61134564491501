<template>
  <b-modal
    :id="id"
    :title="title"
    :centered="centered"
    :size="size"
    header-class="p-2 border-bottom-0"
    footer-class="p-2 border-top-0"
    class="p-3 p-sm-1"
    @shown="onShown"
    @hidden="$emit('onHidden')"
  >
    <p>{{ message }}</p>
    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button @click="$bvModal.hide(id)" :disabled="okLoading">{{
          $t("general.cancel")
        }}</b-button
        ><b-button
          :variant="okVariant"
          @click="okClicked"
          :disabled="okLoading"
          >{{ $t("general.ok") }}</b-button
        ></slot
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      okLoading: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "md",
    },
    okVariant: {
      type: String,
      required: false,
      default: "primary",
    },
    loadOnOk: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    okClicked(oEvent) {
      this.$emit("onOk", oEvent);
      if (this.loadOnOk) this.okLoading = true;
    },
    onShown() {
      this.okLoading = false;
    },
  },
  mounted() {
    this.onShown();
  },
};
</script>

<style></style>
