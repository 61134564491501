<template>
  <div>
    <b-overlay :show="show" no-wrap fixed> </b-overlay>
    <b-button
      v-b-tooltip.hover
      :title="$t('HeaderCase.AcceptCase.title')"
      size="sm"
      variant="primary"
      :disabled="disabled"
      @click="showMsgBoxOne"
    >
      <b-icon-check2-square
    /></b-button>
    <confirm
      :id="confirmationModalId"
      :centered="true"
      okVariant="danger"
      :title="$t('HeaderCase.AcceptCase.title')"
      :message="$t('HeaderCase.AcceptCase.confirmation')"
      :loadOnOk="false"
      :errorMessage="errorMessage"
      @onOk="confirmAcceptCase"
      @onHidden="missingFields = false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import Confirm from "../general/confirm.vue";

export default {
  components: { Confirm },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      show: false,
      missingFields: false,
      confirmationModalId: "confirm-accept-case-modal",
    };
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader", message: "message" }),
    errorMessage() {
      if (this.missingFields) {
        return this.$t("HeaderCase.AcceptCase.missingFields");
      }
      return null;
    },
  },
  methods: {
    ...mapActions("Cases", ["putAcceptedStatus"]),
    validateReviewerFields() {
      var check = true;
      for (var index in this.caseHeader.caseFields) {
        var currentField = this.caseHeader.caseFields[index];
        if (
          currentField.mandatory &&
          (!currentField.fieldValue || currentField.fieldValue == "")
        ) {
          Vue.set(currentField, "status", false);
          check = false;
        } else {
          Vue.set(currentField, "status", true);
        }
      }
      return check;
    },
    confirmAcceptCase(event) {
      if (!this.validateReviewerFields()) {
        event.preventDefault();
        this.missingFields = true;
        return;
      }

      this.show = true;
      this.$bvModal.hide(this.confirmationModalId);
      this.putAcceptedStatus({
        idCases: this.caseHeader.idCase,
      })
        .then(() => {
          this.$emit("accepted");
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            title: this.$t("HeaderCase.AcceptCase.acceptingCaseError"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.show = false;
        });
    },
    showMsgBoxOne() {
      this.$bvModal.show(this.confirmationModalId);
    },
  },
};
</script>

<style></style>
