<template>
  <b-modal
    :id="id"
    :title="title"
    class="p-3 p-sm-1"
    @shown="onShown"
    :header-bg-variant="variantHeader"
    :header-text-variant="variantText"
  >
    <p>{{ message }}</p>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button @click="$bvModal.hide(id)" :disabled="okLoading">{{
          $t("general.cancel")
        }}</b-button
        ><b-button variant="primary" @click="okClicked" :disabled="okLoading">{{
          $t("general.ok")
        }}</b-button></slot
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      okLoading: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    variantHeader: {
      type: String,
      required: false,
      default: "light",
    },
    variantText: {
      type: String,
      required: false,
      default: "dark",
    },
  },
  methods: {
    okClicked(oEvent) {
      this.$emit("onOk", oEvent);
      this.okLoading = true;
    },
    onShown() {
      this.okLoading = false;
    },
  },
  mounted() {
    this.onShown();
  },
};
</script>

<style></style>
