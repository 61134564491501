<template>
  <b-button
    variant="primary"
    v-b-tooltip.hover
    size="sm"
    :title="
      $t(
        isPartial
          ? 'export.buttons.partialCostCalculation'
          : 'export.buttons.costCalculation'
      )
    "
    v-b-modal="idModal"
    :disabled="disabled"
  >
    <b-icon-file-earmark-arrow-down />
    <b-modal
      :id="idModal"
      :title="$t('export.title')"
      ok-only
      @ok="onDownloadClick"
      @hidden="resetState"
    >
      <b-overlay :show="dowloading">
        <b-alert
          variant="danger"
          :show="error"
          dismissible
          fade
          @dismissed="dismissError"
        >
          {{ error }}
        </b-alert>
        <b-form-group
          :label="$t('export.viewMode.label') + ': '"
          label-for="view-mode-select"
          invalid-feedback="Missing export view mode"
          label-cols="12"
          content-cols="12"
          :state="state.viewMode"
        >
          <b-form-select
            id="view-mode-select"
            v-model="values.viewMode"
            :options="fields.viewModes"
            :state="state.viewMode"
            required
          />
        </b-form-group>
        <b-form-group
          :label="$t('export.format.label') + ': '"
          label-for="format-select"
          invalid-feedback="Missing format"
          label-cols="12"
          content-cols="12"
          :state="state.format"
        >
          <b-form-select
            id="format-select"
            v-model="values.format"
            :options="fields.formats"
            :state="state.format"
            required
          />
        </b-form-group>
        <template #overlay>
          <b-col class="text-center">
            <simple-loader />
          </b-col>
          <b-col class="text-center">
            {{ $t("general.downloading") }}
          </b-col>
        </template>
      </b-overlay>
      <template v-slot:modal-ok>
        <b-icon-download />
        {{ $t("general.download") }}
      </template>
    </b-modal>
  </b-button>
</template>
<script>
export default {
  props: {
    idCalculation: {
      required: true,
    },
    idModal: {
      type: String,
      required: false,
      default: "export-modal",
    },
    isPartial: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    sorting: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    fields() {
      return {
        viewModes: [
          {
            text: this.$t("export.viewMode.instruction"),
            value: null,
          },
          {
            text: this.$t("general.internal"),
            value: false,
          },
          {
            text: this.$t("general.external"),
            value: true,
          },
        ],
        formats: [
          {
            text: this.$t("export.format.instruction"),
            value: null,
          },
          {
            text: this.$t("export.format.options.pdfWithoutComments"),
            value: "PDFWithoutComments",
          },
          {
            text: this.$t("export.format.options.pdfWithComments"),
            value: "PDFWithComments",
          },
          {
            text: this.$t("export.format.options.excelWithoutComments"),
            value: "ExcelWithoutComments",
          },
          {
            text: this.$t("export.format.options.excelWithComments"),
            value: "ExcelWithComments",
          },
        ],
      };
    },
  },
  data() {
    return {
      dowloading: false,
      error: null,
      DocExtension: {
        PDFWithoutComments: {
          extension: ".pdf",
          startFileName: 8,
        },
        PDFWithComments: {
          extension: ".pdf",
          startFileName: 8,
        },
        ExcelWithoutComments: {
          extension: ".xlsx",
          startFileName: 9,
        },
        ExcelWithComments: {
          extension: ".xlsx",
          startFileName: 9,
        },
      },
      values: {
        viewMode: null,
        format: null,
      },
      state: {
        viewMode: null,
        format: null,
      },
    };
  },
  methods: {
    buildUrl() {
      const resourceType = this.isPartial ? "subcase/" : "";
      const base = `/cases/export/${resourceType}`;
      const options = `?format=${this.values.format}&external=${this.values.viewMode}&subcaseSorting=${this.sorting}`;
      return base + this.idCalculation + options;
    },
    onDownloadClick(event) {
      event.preventDefault();
      if (this.validateDownload()) {
        this.downloadFile();
      }
    },
    validateDownload() {
      this.state.viewMode = this.values.viewMode !== null;
      this.state.format = this.values.format !== null;
      return this.state.viewMode && this.state.format;
    },
    async downloadFile() {
      let url = this.buildUrl();
      this.dowloading = true;
      await this.$api
        .axios({
          url: url,
          method: "GET",
          responseType: "blob",
        })
        .then((response) => {
          let blob = new Blob([response.data]);

          var filename = "";
          var docExtension = this.DocExtension[this.values.format];
          var disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex =
              /filename\*[^;=\n]*=UTF-8''((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          filename = decodeURI(filename);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          const viewMode = this.values.viewMode ? "external" : "internal";
          link.href = url;
          link.setAttribute(
            "download",
            filename.substring(
              0,
              filename.length - docExtension.extension.length
            ) +
              " - " +
              this.$t(`general.${viewMode}`) +
              docExtension.extension
          );
          document.body.appendChild(link);
          link.click();
        })
        .then(() => {
          this.$bvModal.hide(this.idModal);
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.dowloading = false;
        });
    },
    dismissError() {
      this.error = null;
    },
    resetState() {
      this.dowloading = false;
      this.dismissError();
      this.state = {
        viewMode: null,
        format: null,
      };
      this.values = {
        viewMode: null,
        format: null,
      };
    },
  },
};
</script>
