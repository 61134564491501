<template>
  <div>
    <b-overlay :show="show" no-wrap fixed> </b-overlay>
    <b-button
      v-b-tooltip.hover
      :title="$t('HeaderCase.SubmittedCase.title')"
      size="sm"
      variant="primary"
      :disabled="disabled"
      @click="showMsgBoxOne"
    >
      <b-icon-box-arrow-up
    /></b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters("Cases", { caseHeader: "caseHeader", message: "message" }),
  },
  methods: {
    ...mapActions("Cases", ["putSubmittedStatus"]),
    showMsgBoxOne() {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(this.$t("HeaderCase.SubmittedCase.confirmation"), {
          title: this.$t("HeaderCase.SubmittedCase.title"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          cancelTitle: this.$t("general.cancel"),
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.show = true;
            this.putSubmittedStatus({
              idCases: this.caseHeader.idCase,
            })
              .then(() => {
                this.$emit("submitted");
                this.show = false;
              })
              .catch((error) => {
                this.$bvToast.toast(error, {
                  title: this.$t("general.error"),
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.show = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
